@import "../../base/vars";
@import "../../base/mixin/index";

.select {
	display: block;
	height: rem(58);
	width: 100%;
	padding: 0 rem(27);
	font-size: rem(16);
	line-height: 1;
	font-weight: 400;
	background-color: $control-bg;
	border: 1px solid $control-border;
	color: $control-placeholder;
	appearance: none;
	transition: box-shadow .3s ease;
	background-image: url("../../../img/svg/angle-down.svg");
	background-repeat: no-repeat;
	background-size: 20px 11px;
	background-position: calc(100% - 16px) center;
	
	&:focus {
		box-shadow: $shadow;
		//background-position: calc(100% - 50px) center;
	}
	
	&.invalid {
		border-color: $danger;
	}
	
	&.valid {
		border-color: $success;
	}
}