@import "../../base/vars";
@import "../../base/mixin/index";

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: $btn-bg;
  border: 1px solid $btn-bg;
  color: $btn-color;
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
  font-size: rem(18);
  line-height: 1;
  padding: rem(10, 15);
  
  svg {
    margin-left: rem(16);
  }
  
  &-warning {
    background-color: $btn-warning-bg;
    border-color: $btn-warning-bg;
    color: $btn-warning-color;
  }
  
  &-md {
    height: rem(55);
    min-width: rem(242);
    
    @media screen and (max-width: 991px) and (orientation: landscape){
      height: rem(40);
      min-width: rem(160);
    }
    
    @media screen and (max-width: 767px){
      height: rem(40);
      min-width: rem(160);
    }
  }
  
  &-sm {
    height: rem(44);
    min-width: rem(132);
    
    @media screen and (max-width: 991px) and (orientation: landscape){
      height: rem(40);
      min-width: rem(100);
    }
    
    @media screen and (max-width: 767px){
      height: rem(40);
      min-width: rem(100);
    }
  }
}

.hamburger {
  font: inherit;
	display: none;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity,filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
  z-index: 999;
	
	@media screen and (max-width: 991px){
		display: inline-block;
	}
  
  &-box {
    position: relative;
    display: inline-block;
    width: rem(40);
    height: rem(24);
  }
  
  &-inner {
    position: absolute;
    width: rem(40);
    height: rem(4);
    top: auto;
    bottom: 0;
    transition-property: transform;
    border-radius: rem(4);
    background-color: $btn-warning-bg;
    transition-delay: .13s;
    transition-timing-function: cubic-bezier(.55,.055,.675,.19);
    transition-duration: .13s;
    
    ._js-open-menu-drop & {
      transition-delay: .22s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: translate3d(0, rem(-10), 0) rotate(-45deg);
    }
    
    &::after, &::before {
      position: absolute;
      display: block;
      content: "";
      width: rem(40);
      height: rem(4);
      transition-timing-function: ease-in-out;
      transition-duration: .15s;
      transition-property: transform;
      border-radius: rem(4);
      background-color: $btn-warning-bg;
    }
    
    &::before {
      top: rem(-10);
      
      ._js-open-menu-drop & {
        top: 0;
        transition: top .1s cubic-bezier(.33333,0,.66667,.33333) .16s, transform .13s cubic-bezier(.215,.61,.355,1) .25s;
        transform: rotate(-90deg);
      }
    }
    
    &::after {
      bottom: rem(-10);
      top: rem(-20);
      transition: top .2s cubic-bezier(.33333,.66667,.66667,1) .2s,opacity .1s linear;
      
      ._js-open-menu-drop & {
        top: 0;
        transition: top .2s cubic-bezier(.33333,0,.66667,.33333),opacity .1s linear .22s;
        opacity: 0;
      }
    }
  }
}

.circleflash {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  
  span {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-radius: $rounded;
    background-color: $btn-color;
    transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  
  svg {
    use {
      transition: fill 0.5s ease-in-out;
      fill: $btn-color !important;
    }
  }
  
  &:hover {
    color: $text-dark !important;
  
    span {
      width: 225%;
      height: 562.5px;
    }
    
    svg {
      use {
        fill: $text-dark !important;
      }
    }
  }
  &:active {
    background-color: #e2e2e2;
  }
}
