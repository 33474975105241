@import "../base/vars";
@import "../base/mixin/index";

.header {
	padding: rem(23, 0, 24);
	transition:
			paddimg .15s ease-in-out,
			box-shadow .15s ease-in-out,
			background-color .15s ease-in-out;
	
	@media screen and (max-width: 991px){
		padding: rem(15, 0, 16);
	}
	
	._js-fixed & {
		box-shadow: $shadow;
		padding: rem(10, 0);
		background-color: $header-bg;
		
		@media screen and (max-width: 991px){
			background-color: rgba($header-bg, .8);
		}
	}
	
	.container-fluid {
		display: flex;
		justify-content: space-between;
		align-items: center;
		max-width: 1600px;
		margin: 0 auto;
	}
	
	&:not(.header-float) {
		background-color: $header-bg;
		
		.navigation {
			&__link {
				text-shadow: 2px 0 0 $sec-bg-contrast;
			}
		}
	}
	
	&.header-float {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
	}
}

.logo {
	width: rem(88);
	position: relative;
	
	.main-logo {
		opacity: 1;
		
		@media screen and (max-width: 767px) {
			opacity: 0;
			
			._js-fixed & {
				opacity: 1;
			}
		}
	}
	
	.duplicate-logo {
		position: absolute;
		top: 0;
		left: -1px;
		z-index: 1;
		opacity: 0;
		
		@media screen and (max-width: 767px) {
			opacity: 1;
			
			._js-fixed & {
				opacity: 0;
			}
		}
	}
	
	svg {
		height: auto;
		transition: width .15s ease-in-out, opacity .15s ease-in-out;
		
		@media screen and (min-width: 576px) and (max-width: 991px){
			width: rem(70);
		}
		
		@media screen and (max-width: 575px){
			width: rem(60);
		}
		
		._js-fixed & {
			width: rem(60);
			
			@media screen and (max-width: 575px){
				width: rem(50);
			}
		}
	}
}

.navigation {
	@media screen and (max-width: 991px){
		display: none;
	}
	&__link {
		font-weight: 400;
		font-size: rem(18);
		line-height: 1;
		letter-spacing: $letter-spacing;
		color: $text;
		text-shadow: 2px 0 0 $body-bg;
		transition:
				font-weight .15s ease-in-out,
				color .15s ease-in-out,
				text-shadow .15s ease-in-out;
		
		&._js-active {
			font-weight: 600;
			pointer-events: none;
			user-select: none;
		}
		
		._js-fixed & {
			text-shadow: 2px 0 0 $sec-bg-contrast;
		}
		
		& + & {
			margin-left: rem(50);
			
			@media screen and (min-width: 992px) and (max-width: 1199px){
				margin-left: rem(20);
			}
		}
		
		&:hover {
			color: $text-warning;
		}
	}
}

.authorization {
	display: flex;
	
	@media screen and (max-width: 991px){
		display: none;
	}
	
	.btn {
		& + .btn {
			margin-left: rem(24);
		}
	}
}

.menu-drop {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 98;
	background-color: $header-bg;
	min-height: 100vh;
	height: 1px;
	overflow-y: auto;
	padding: rem(70, 15);
	transform: translateY(-100%);
	transition: transform .5s ease-in-out;
	
	@media screen and (max-width: 991px){
		display: flex;
		flex-direction: column;
		
		._js-open-menu-drop & {
			transform: translateY(0);
		}
	}
	
	.navigation {
		display: flex;
		flex-direction: column;
		align-items: center;
		
		&__link {
			display: block;
			width: 100%;
			text-align: center;
			margin-left: 0 !important;
			padding: rem(20) 0;
			border-bottom: 1px solid $text-secondary;
		}
	}
	
	.authorization {
		display: flex;
		justify-content: center;
		margin-top: rem(40);
	}
}