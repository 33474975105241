@import "../../base/vars";
@import "../../base/mixin/index";

.form {
	position: relative;
	color: $text-secondary;
	overflow: hidden;
	
	&__group {
		& + & {
			margin-top: rem(14);
		}
	}
	
	&__control {
		display: block;
		height: rem(58);
		width: 100%;
		padding: 0 rem(27);
		font-size: rem(16);
		line-height: 1;
		font-weight: 400;
		background-color: $control-bg;
		border: 1px solid $control-border;
		transition: box-shadow .3s ease, border-color .3s ease;
		
		@media screen and (max-width: 767px){
			padding: 0 rem(15);
			height: rem(40);
		}
		
		&.invalid {
			border-color: $danger;
		}
		
		&.valid {
			border-color: $success;
		}
		
		@at-root textarea#{&} {
			height: auto;
			padding-top: rem(15);
			padding-bottom: rem(15);
			resize: none;
			transition: box-shadow .3s ease, opacity .3s ease;
			
			&:focus {
				&::placeholder {
					text-indent: 0;
					opacity: 0;
					transition: opacity .3s ease;
				}
			}
		}
		
		&::placeholder {
			color: $control-placeholder;
			text-indent: 0;
			transition: text-indent .3s ease;
		}
		
		&:hover {
			border-color: $warning;
		}
		
		&:focus {
			box-shadow: $shadow;
			border-color: $control-border;
			
			&::placeholder {
				text-indent: -100%;
				transition: text-indent .3s ease;
			}
		}
	}
	
	&__footer {
		margin-top: rem(48);
		font-size: rem(12);
		line-height: 1;
		
		@media screen and (max-width: 575px){
			margin-top: rem(20);
		}
		
		.btn {
			@media screen and (max-width: 575px){
				margin-top: rem(40);
			}
		}
	}
	
	&__link {
		position: relative;
		color: inherit;
		
		&::after {
			position: absolute;
			content: '';
			top: 100%;
			left: 0;
			right: 0;
			height: 1px;
			background-color: $text-secondary;
			z-index: 1;
			transition: right .15s ease-in-out;
		}
		
		&:hover {
			&::after {
				right: 100%;
			}
		}
	}
	
	&__captcha {
	
	}
}