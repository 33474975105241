@import "../base/vars";
@import "../base/mixin/index";

.footer {
	box-shadow: $shadow;
	padding: rem(23, 0, 24);
	background-color: $footer-bg;
	
	.container-fluid {
		max-width: 1600px;
		margin: 0 auto;
	}
	
	[class*=col] {
		& + [class*=col] {
			@media screen and (max-width: 575px){
				margin-top: rem(40);
			}
		}
	}
	
	.logo {
		@media screen and (max-width: 575px){
			margin-bottom: rem(20);
		}
	}
}

.extra {
	@media screen and (min-width: 576px) and (max-width: 991px){
		text-align: center;
		margin-top: rem(24);
	}
	
	@media screen and (max-width: 575px){
		display: flex;
		flex-direction: column;
	}
	
	&__link {
		color: $text-contrast;
		transition: color .15s ease-in-out;
		
		&:hover {
			color: $text-warning;
		}
		
		& + & {
			margin-left: rem(37);
			
			@media screen and (max-width: 575px){
				margin-left: 0;
				margin-top: rem(15);
			}
		}
	}
}

.copyright {
	color: $text-contrast;
	letter-spacing: $letter-spacing;
	font-size: rem(16);
	line-height: 130%;
}