@import "base/vars";
@import "base/mixin/index";

body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	font-size: rem(16);
	line-height: 1.45;
	font-family: $font_work;
	color: $text;
	background-color: $body-bg;
}

/* preloading... */
.loader {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: $contrast;
	z-index: 1001;
	
	&__icon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}
}
/* end preloading... */

.section-form {
	padding: rem(106) 0;
	
	@media screen and (min-width: 768px) and (max-width: 1366px){
		padding: rem(40) 0;
	}
	
	@media screen and (max-width: 767px){
		padding: rem(40) 0;
	}
	
	.form {
		width: 100%;
		max-width: rem(705);
		margin: auto;
	}
	
	h2 {
		margin-bottom: rem(56);
	}
	
	h3 {
		margin-bottom: rem(30);
	}
	
	.tab {
		margin: 0 auto rem(40);
	}
	
	.btn-md {
		min-width: rem(226);
		
		@media screen and (max-width: 991px) and (orientation: landscape){
			min-width: rem(160);
		}
		
		@media screen and (max-width: 767px){
			min-width: rem(160);
		}
	}
}

.tab {
	position: relative;
	display: flex;
	justify-content: space-between;
	max-width: rem(300);
	width: 100%;
	
	&::before {
		position: absolute;
		content: '';
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		height: 1px;
		z-index: 1;
		background-color: $control-border;
	}
	
	&__item {
		position: relative;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: rem(32);
		height: rem(32);
		border: 1px solid $control-border;
		color: $light;
		background-color: $control-bg;
		user-select: none;
		cursor: pointer;
		
		&._js-active {
			border-color: $btn-warning-bg;
			background-color: $btn-warning-bg;
			color: $btn-warning-color;
			cursor: default;
		}
	}
}

.list-custom {
	
	li {
		position: relative;
		padding-left: rem(55);
		color: $text-warning;
		font-size: rem(30);
		line-height: 1;
		font-weight: 600;
		
		@media screen and (max-width: 991px){
			font-size: rem(22);
			padding-left: rem(42);
		}
		
		& + li {
			margin-top: rem(20);
		}
		
		&::before {
			position: absolute;
			content: '';
			top: rem(8);
			left: 0;
			width: rem(25);
			height: rem(15);
			background: url("../img/svg/check.svg") no-repeat 0 0 / 100% 100%;
			z-index: 1;
			
			@media screen and (max-width: 991px){
				width: rem(19);
				height: rem(12);
			}
		}
	}
}

.section-main {
	height: 100vh;
	background-repeat: no-repeat;
	background-position: 100% 0;
	background-size: auto 100%;
	//background-color: $body-bg;
	
	.container-fluid {
		max-width: 1600px;
	}
	
	h1 {
		margin: 0 0 rem(30);
		text-shadow: 2px 0 0 $body-bg;
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin: 0 0 rem(15);
		}
		
		@media screen and (max-width: 991px) and (orientation: landscape){
			font-size: rem(36);
			line-height: 1;
			margin: 0 0 rem(10);
		}
		
		@media screen and (max-width: 767px){
			margin: 0 0 rem(15);
			font-size: rem(36);
			line-height: 1;
		}
		
		.warning {
			color: $text-warning;
			text-shadow: none;
		}
	}
	
	p {
		max-width: 520px;
		font-size: rem(22);
		line-height: rem(30);
		color: $text-secondary;
		text-shadow: 2px 0 0 $body-bg;
		margin-bottom: rem(62);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-bottom: rem(40);
		}
		
		@media screen and (max-width: 991px) and (orientation: landscape){
			font-size: rem(18);
			line-height: 1.2;
			margin-bottom: rem(10);
		}
		
		@media screen and (max-width: 767px){
			margin-bottom: rem(20);
			font-size: rem(18);
		}
	}
}

.section-about {
	padding-top: 13vw;
	padding-bottom: 13vw;
	background-color: $sec-bg-contrast;
	position: relative;
	overflow: hidden;
	
	@media screen and (min-width: 768px) and (max-width: 1366px){
		padding-top: 9.75vw;
		padding-bottom: 9.75vw;
	}
	
	@media screen and (max-width: 767px){
		padding-top: 40px;
		padding-bottom: 40px;
	}
	
	.picture {
		@media screen and (min-width: 768px){
			position: absolute;
			width: 50vw;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
		}
	}
	
	h2 {
		margin-bottom: rem(28);
		
		@media screen and (max-width: 767px){
			text-align: center;
		}
	}
	
	p {
		font-size: rem(22);
		max-width: rem(554);
		line-height: rem(28);
		
		@media screen and (max-width: 767px){
			max-width: none;
		}
	}
}

.section-advertisers {
	padding-top: rem(120);
	padding-bottom: rem(192);
	background-color: $sec-bg-contrast;
	
	@media screen and (min-width: 768px) and (max-width: 1366px){
		padding-top: rem(90);
		padding-bottom: rem(144);
	}
	
	@media screen and (max-width: 767px){
		padding-top: rem(40);
		padding-bottom: rem(40);
	}
	
	.container-fluid {
		max-width: 1366px;
	}
	
	h2 {
		margin-bottom: rem(120);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-bottom: rem(90);
		}
		@media screen and (max-width: 767px){
			margin-bottom: 40px;
		}
	}
	
	h6 {
		line-height: 1.25;
	}
	
	p {
		font-size: rem(18);
		line-height: rem(26);
	}
	
	.articles {
		margin-bottom: rem(93);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-bottom: rem(70);
		}
		
		@media screen and (max-width: 767px){
			margin-bottom: rem(40);
		}
		
		.row {
			& + .row {
				margin-top: rem(64);
				
				@media screen and (min-width: 768px) and (max-width: 1366px){
					margin-top: rem(48);
				}
				
				@media screen and (max-width: 767px){
					margin-top: rem(0);
				}
			}
		}
	}
}

.section-publishers {
	padding-top: rem(135);
	padding-bottom: rem(135);
	background-position: 0 0;
	background-repeat: repeat-x;
	background-size: auto 100%;
	background-color: $sec-bg-dark;
	
	@media screen and (min-width: 768px) and (max-width: 1366px){
		padding-top: rem(100);
		padding-bottom: rem(100);
	}
	
	@media screen and (max-width: 767px){
		padding-top: rem(40);
		padding-bottom: rem(40);
	}
	
	.container-fluid {
		max-width: 1553px;
	}
	
	h2 {
		margin-bottom: rem(119);
		color: $text-contrast;
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-bottom: rem(90);
		}
		
		@media screen and (max-width: 767px){
			margin-bottom: rem(40);
		}
	}
	
	p {
		color: $text-contrast;
		font-size: rem(22);
		line-height: rem(26);
	}
	
	.articles {
		margin-bottom: rem(88);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-bottom: rem(50);
		}
		
		@media screen and (max-width: 767px){
			margin-bottom: rem(20);
		}
		
		.row {
			& + .row {
				margin-top: rem(77);
				
				@media screen and (min-width: 768px) and (max-width: 1366px){
					margin-top: rem(57);
				}
				
				@media screen and (max-width: 767px){
					margin-top: 0;
				}
			}
		}
	}
}

.section-features {
	background-color: $sec-bg-contrast;
	padding-top: rem(173);
	padding-bottom: rem(206);
	overflow: hidden;
	
	@media screen and (min-width: 768px) and (max-width: 1366px){
		padding-top: rem(130);
		padding-bottom: rem(155);
	}
	
	@media screen and (max-width: 767px){
		padding-top: rem(40);
		padding-bottom: rem(40);
	}
	
	.container-fluid {
		max-width: 1270px;
	}
	
	p {
		font-size: rem(22);
		line-height: 130%;
		letter-spacing: $letter-spacing;
	}
	
	.text {
		max-width: 486px;
	}
	
	.accordion {
		margin-top: rem(80);
		margin-bottom: rem(76);
		max-width: 486px;
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-top: rem(60);
			margin-bottom: rem(57);
		}
		
		@media screen and (max-width: 767px){
			margin-top: rem(40);
			margin-bottom: rem(60);
		}
	}
	
	.picture {
		display: block;
		text-align: center;
		
		img {
			@media screen and (max-width: 575px){
				max-height: 300px;
			}
		}
	}
}

.accordion {
	&__item {
		border-bottom: 1px solid rgba($secondary-dark, .15);
		
		&._js-active {
			border-color: $accordion-color-warning;
			border-width: rem(3);
		}
	}
	
	&__header {
		display: flex;
		cursor: pointer;
		padding: rem(22) 0;
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			padding: rem(16) 0;
		}
		
		svg {
			margin-right: rem(15);
			fill: $text-dark;
			transition: fill .15s ease-in-out;
			
			._js-active & {
				fill: $text-warning;
			}
		}
		
		h5 {
			color: $accordion-color;
			font-size: rem(22);
			line-height: rem(26);
			font-weight: 900;
			transition: color .15s ease-in-out;
			
			._js-active & {
				color: $accordion-color-warning;
			}
		}
	}
	
	&__body {
		padding: 0 0 rem(22);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			padding: 0 0 rem(16);
		}
		
		p {
			letter-spacing: $letter-spacing;
			color: $text-secondary;
		}
	}
}

.section-services {
	position: relative;
	padding-top: rem(218);
	padding-bottom: rem(198);
	
	@media screen and (min-width: 768px) and (max-width: 1366px){
		padding-top: rem(164);
		padding-bottom: rem(148);
	}
	
	@media screen and (max-width: 767px){
		padding-top: rem(40);
		padding-bottom: rem(40);
	}
	
	.picture {
		@media screen and (min-width: 768px){
			position: absolute;
			width: 50vw;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
		}
	}
	
	h2 {
		@media screen and (max-width: 767px){
			text-align: center;
		}
	}
	
	p {
		max-width: rem(410);
		font-size: rem(22);
		line-height: 130%;
		letter-spacing: $letter-spacing;
		
		@media screen and (max-width: 767px){
			max-width: none;
		}
	}
	
	.list-custom {
		margin-top: rem(70);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-top: rem(52);
		}
	}
}

.section-contact {
	background-color: $sec-bg-contrast;
	padding-top: rem(162);
	padding-bottom: rem(123);
	
	@media screen and (min-width: 768px) and (max-width: 1366px){
		padding-top: rem(122);
		padding-bottom: rem(92);
	}
	
	@media screen and (max-width: 767px){
		padding-top: rem(40);
		padding-bottom: rem(40);
	}
	
	.form {
		width: 100%;
		max-width: rem(740);
		margin: auto;
	}
	
	h2 {
		margin-bottom: rem(52);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-bottom: rem(40);
		}
		
		@media screen and (max-width: 767px){
			margin-bottom: rem(40);
		}
	}
	
	.btn-md {
		min-width: rem(196);
	}
}

[class*=section-] {
	p {
		letter-spacing: $letter-spacing;
		
		b {
			font-weight: 900;
		}
	}
	
	h2 + p {
		margin-top: rem(15);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-top: rem(12);
		}
	}
	
	p + p {
		margin-top: rem(32);
		
		@media screen and (min-width: 768px) and (max-width: 1366px){
			margin-top: rem(24);
		}
	}
}

.article {
	@media screen and (min-width: 576px) and (max-width: 767px){
		margin-bottom: rem(20);
	}
	@media screen and (max-width: 575px){
		margin-bottom: rem(40);
	}
	
	&__figure {
		@media screen and (max-width: 575px){
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: rem(15);
		}
	}
	
	&__title {
		margin-bottom: rem(8);
		
		@media screen and (max-width: 575px){
			text-align: center;
		}
	}
	
	&__text {
		@media screen and (max-width: 575px){
			text-align: center;
		}
	}
}