@import "../../base/vars";
@import "../../base/mixin/index";

.checkbox {
	display: inline-flex;
	flex-wrap: wrap;
	align-items: center;
	font-size: rem(12);
	line-height: 1;
	color: $text-secondary;
	user-select: none;

	input[type=checkbox] {
		display: none;

		&:checked + .checkbox__icon {
			&::before {
				opacity: 1;
				visibility: visible;
				top: 50%;
			}
		}
	}

	&__icon {
		position: relative;
		top: 0;
		margin-right: rem(11);
		display: inline-flex;
		width: rem(20);
		height: rem(20);
		border: 1px solid $control-checkbox-border;
		background-color: $control-bg;
		backface-visibility: hidden;

		&::before {
			position: absolute;
			content: '\2714';
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			backface-visibility: hidden;
			transition:
					visibility .15s ease-in-out,
					opacity .15s ease-in-out,
					top .15s ease-in-out;
		}
	}

	&__link {
		text-decoration: underline;
		color: inherit;

		&:hover {
			text-decoration: none;
		}
	}
}