/* visible */
.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}
/* end visible */

.flex {
	display: flex;
}

.inline-flex {
	display: inline-flex;
}

/* overflow */
.overflow-y {
	overflow-y: auto;
	overflow-x: hidden;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-scroll {
	overflow: scroll !important;
}

.overflow-visible {
	overflow: visible !important;
}
/* end overflow */

/* word- */
.word-wrap {
	word-break: break-all !important;
	word-break: break-word !important;
	word-wrap: break-word !important;
}
.word-break {
	word-wrap: break-word !important;
}
/* end word- */

/* text- */
.text-truncate {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
}
.text-nowrap {
	white-space: nowrap !important;
}
.text-normal {
	white-space: normal !important;
}
.text-lowercase {
	text-transform: lowercase !important;
}
.text-uppercase {
	text-transform: uppercase !important;
}
.text-capitalize {
	text-transform: capitalize !important;
}
.text-through {
	text-decoration: line-through !important;
}
.text-decoration-none {
	text-decoration: none !important;
}
/* end text- */

/* position */
.position-relative {
	position: relative !important;
}
.position-absolute {
	position: absolute !important;
}
.position-fixed {
	position: fixed !important;
}
.position-sticky {
	position: sticky !important;
}
/* end position */

/* text align */
.text-center {
	text-align: center !important;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-justify {
	text-align: justify !important;
}


@media (min-width: 576px) {
	.text-sm-left {
		text-align: left !important;
	}
	.text-sm-right {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-left {
		text-align: left !important;
	}
	.text-md-right {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-left {
		text-align: left !important;
	}
	.text-lg-right {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1200px) {
	.text-xl-left {
		text-align: left !important;
	}
	.text-xl-right {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-top {
	vertical-align: top !important;
}
/* end text align */

.border-0 {
	border: none !important;
}
.cursor-pointer {
	cursor: pointer !important;
}
.float-right {
	float: right;
}
.h-inherit {
	height: inherit !important;
}