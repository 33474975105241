@import "../base/mixin/index";

$contrast: #ffffff;
$dark: #000000;
$secondary-dark: #2B292D;
$secondary: #525252;
$light: #AEAEAE;
$light-extra: #AAAAAA;
$warning: #F38B11;
$danger: red;
$success: green;

$body-bg: #F5F9FA;
$sec-bg-contrast: $contrast;
$sec-bg-dark: $dark;
$header-bg: $contrast;
$footer-bg: $dark;

$text: $dark;
$text-contrast: $contrast;
$text-dark: $secondary-dark;
$text-secondary: $secondary;
$text-warning: $warning;

$control-bg: $contrast;
$control-color: $text-secondary;
$control-placeholder: $light-extra;
$control-border: $light;
$control-border-danger: $danger;
$control-border-success: $success;
$control-checkbox-border: $secondary;

$btn-bg: $dark;
$btn-color: $contrast;
$btn-warning-bg: $warning;
$btn-warning-color: $contrast;

$accordion-color: $secondary-dark;
$accordion-color-warning: $text-warning;

$font_work: 'Work Sans', sans-serif;
$letter-spacing: 0.005em;
$shadow: 0px 6px 10px rgba(0, 0, 0, 0.05);
$rounded: 50%