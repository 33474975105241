@import "../../../style/base/vars";
@import "../../../style/base/mixin/index";

/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  position: relative;
  background-color: $control-bg;
  border: 1px solid $control-border;
  transition: box-shadow .3s ease, border-color .3s ease;
  
  &.invalid {
    border-color: $danger;
  }
  
  &.valid {
    border-color: $success;
  }
}

.selectric .label {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 rem(58) 0 0;
  padding: 0 rem(27);
  font-size: rem(16);
  line-height: 1;
  font-weight: 400;
  color: $text-secondary;
  height: rem(58);
  
  @media screen and (max-width: 767px){
    padding: 0 rem(15);
    height: rem(40);
  }
  
}

.selectric .button {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  width: rem(58);
  height: rem(58);
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgOCA1IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjgiIGhlaWdodD0iNSI+PHBhdGggZD0iTTcuNzgxIDEuNDQ0Yy0uMjgxLjMwNy0zLjIzOCAzLjMxMS0zLjIzOCAzLjMxMS0uMTUxLjE2NC0uMzQ3LjI0NS0uNTQ0LjI0NS0uMTk3IDAtLjM5NC0uMDgyLS41NDMtLjI0NSAwIDAtMi45NTctMy4wMDMtMy4yMzktMy4zMTEtLjI4MS0uMzA3LS4zLS44NTkgMC0xLjE4OC4zMDEtLjMyOC43MTktLjM1NCAxLjA4NyAwbDIuNjk1IDIuNzU1IDIuNjk0LTIuNzU1Yy4zNjgtLjM1NC43ODctLjMyOCAxLjA4NyAwIC4zMDEuMzI4LjI4Mi44ODEgMCAxLjE4N3oiIGZpbGw9IiNiYmIiLz48L3N2Zz4K) no-repeat 50% / rem(15) rem(15);
  font: 0/0 a;
  
  @media screen and (max-width: 767px){
    width: rem(40);
    height: rem(40);
  }
  
}

@media \0screen\,screen\9 {
  .selectric .button {
    color: #DDD;
    text-align: center;
    font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  }
}

.selectric-focus .selectric {
  border-color: $control-border;
  box-shadow: $shadow;
}

.selectric-hover .selectric {
  border-color: $warning;
}

.selectric-hover .selectric .button {
  color: #888;
}

.selectric-hover .selectric .button:after {
  border-top-color: #888;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  border-color: #CCC;
  background: #F0F0F0;
  margin-top: 1px;
  border-bottom-width: 1px;
}

.selectric-open .selectric-items {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  visibility: hidden;
  opacity: 0;
  transform: translateY(-20px);
  position: absolute;
  top: 100%;
  left: 0;
  background: #fefefe;
  border: 1px solid #CCC;
  z-index: -1;
  box-shadow: 0 0 10px -6px, inset 0 0 0px 1px #fff;
  margin: 8px 0;
  padding: 10px 0;
  border-radius: 4px;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-items:before,
.selectric-items:after {
  content: "";
  position: absolute;
  top: -9px;
  left: 12px;
  width: 0;
  height: 0;
  border-bottom: 6px solid #BBB;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin-top: 3px;
}

.selectric-items:after {
  margin-top: 4px;
  border-bottom-color: #FFF;
}

.selectric-above .selectric-items:before,
.selectric-above .selectric-items:after {
  top: 100%;
  border-bottom-width: 0;
  border-top: 6px solid #BBB;
  margin-top: 0;
}

.selectric-above .selectric-items:after {
  border-top-color: #FFF;
  margin-top: -1px;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: rem(16);
  line-height: 1.5;
  min-height: rem(40);
}

.selectric-items li {
  display: block;
  padding: rem(10, 30, 10, 10);
  color: $text-secondary;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.selectric-items li.selected {
  background: #E0E0E0;
  color: #444;
}

.selectric-items li.highlighted {
  background: #D0D0D0;
  color: #444;
}

.selectric-items li:after {
  content: "";
  position: absolute;
  z-index: 3;
  top: 0;
  right: -10px;
  width: 30px;
  height: 100%;
  background: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTIgMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEyIiBoZWlnaHQ9IjEwIj48cGF0aCBkPSJNMCA1YzAtLjIyNC4wNzItLjQxNS4yMTctLjU3MmwxLjA1My0xLjE0NWMuMTQ0LS4xNTcuMzItLjIzNi41MjYtLjIzNi4yMDYgMCAuMzgyLjA3OS41MjYuMjM2bDIuMjc2IDIuNDgzIDUuMDc5LTUuNTNjLjE0NS0uMTU3LjMyLS4yMzYuNTI2LS4yMzYuMjA2IDAgLjM4Mi4wNzkuNTI2LjIzNmwxLjA1MyAxLjE0NWMuMTQ0LjE1Ny4yMTcuMzQ4LjIxNy41NzIgMCAuMjI0LS4wNzIuNDE1LS4yMTcuNTcybC02LjY1OCA3LjIzOWMtLjE0NC4xNTctLjMyLjIzNi0uNTI2LjIzNi0uMjA2IDAtLjM4Mi0uMDc5LS41MjYtLjIzNmwtMy44NTYtNC4xOTJjLS4xNDUtLjE1Ny0uMjE3LS4zNDgtLjIxNy0uNTcyeiIgZmlsbD0iI2JiYiIvPjwvc3ZnPgo=) no-repeat 50% 50%;
  opacity: 0;
  -webkit-transition: .2s;
          transition: .2s;
}

.selectric-items li.selected:after {
  opacity: 1;
  right: 0;
}

.selectric-items li:hover {
  background: #F0F0F0;
  color: #444;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: #666 !important;
  user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  user-select: none;
  background: none;
  color: #444;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}