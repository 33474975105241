@function strip-unit($num) {
	@if $num == 0 or $num == auto or $num == '!important' {
		@return $num;
	}
	@else {
		@return $num / 16 + rem;
	}
}

@function rem($values...) {
	$max: length($values);
	$remValues: '';
	
	@for $i from 1 through $max {
		$value: strip-unit(nth($values, $i));
		$remValues: #{$remValues + $value};
		
		@if $i < $max {
			$remValues: #{$remValues + " "};
		}
	}
	
	@return $remValues;
}